.loadingImageContainer{
width:100px;
height:100px;
}

.loadingImage{
    max-width:100%;
    max-height:100%;
    // opacity:1;
    // animation: rotation 1.2s infinite cubic-bezier(0.76, 0, 0.24, 1);
    // animation: rotation 0.7s infinite cubic-bezier(0.45, 0, 0.55, 1);
    animation: rotation 0.7s infinite linear;
}

@keyframes rotation {
    0% {
        // opacity:0;
      transform: perspective(400px) rotatey(0deg);
    }
    50% {
        // opacity:1;
        transform: perspective(400px) rotatey(180deg);
      }
    100% {
        // opacity:0;
      transform: perspective(400px) rotatey(359deg);
    }
  }

@keyframes rotation2 {
    from {
      transform: perspective(400px) rotatey(0deg);
    }
    to {
      transform: perspective(400px) rotatey(359deg);
    }
  }

  @keyframes scale {
    0% {
    // filter:blur(2px);
    opacity:0;
      transform: scale(1);
    }
    50% {
        // filter:blur(0px);
        opacity:0.5;
        transform: scale(1.1);
      }
    100% {
        // filter:blur(2px);
        opacity:0;
      transform: scale(1);
    }
  }