.photobooth{

    .photobooth-nav-wrapper{
        position: absolute;
        top:0;
        width:100%;
        z-index: 9;
        padding:10px;

        a, .linkLike{
            color:white;
            // border-bottom:thin solid white;
        }

        .pointer{
            cursor: pointer;
            float:left;
            // margin-left:20px;
        }
        .photobooth-nav-button{
            line-height: 1.5vw;
            padding:0.1em .5em .3em;
            background-color: white;
            // padding: 0 0.2em 0.3em 0.2em;
            color:black;
            border-radius: 21px;
            border: 2px solid white;
            display: inline-block;
            text-decoration: none;
        
            a{
                text-decoration: none;
            }
        }
        
        .photobooth-nav-button:hover{
            background-color: black;
            color:white;
            border: 2px solid black;
        }
        

        .right-wrapper{
            float:right;

            .photobooth-nav-button{
                margin-left:10px;
            }
        }
    }
}

.errorMessageContainer{
    position: fixed;
    text-align: center;
    top:50%;
    transform: translatey(-50%);
    z-index: 10;
}

#visitorSnapPhoto{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
}

#visitorSnapPreviewCanvas{
    width:100vw;
    height:100vh;
    position:fixed;
    z-index: -1;
}

.flash{
    -moz-animation: flashAnimation 0.3s linear;
    -webkit-animation: flashAnimation 0.3s linear;
    animation: flashAnimation 0.3s linear;
}

.flashContainer{
    background:#fff;
    width:100vw;
    height:100vh;
    opacity:0;
    z-index: 10;
}

@keyframes flashAnimation {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
  }

#visitorSnapPhoto img{
    position: absolute;
    top: 50%;
    transform: translatey(-50%) rotateY(180deg);
}

#webCamWrapper{
width:100vw;
height:100vh;
position:fixed;
z-index: 0;
}

.webcam{
position: absolute;
width:100%;
top:50%;
left:50%;
transform:translate(-50%, -50%) scaleX(-1);
}


@media (max-width: 1000px) {

    .top{
        position: absolute;
        top:0;
        width:100%;
        height: auto;
        line-height: 30px;
        padding: 20px;
        z-index: 9;

        .photobooth-left{
            float:left;
            
            .padding-top{
                line-height: 34px;
            }
        }
        .photobooth-right{
            float:right;
        }
        
        .photobooth-btn{
            background-color: black;
            color:white;
            border-radius: 20px;
            padding:0.1em 0.5em 0.3em 0.5em;
            float: left;
            margin-left: 20px;
            line-height: inherit !important;
            font-size: 22px;
        }
    }

}