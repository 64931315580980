.about{
    font-size:20px;
    line-height:30px;

    .projectInfo{

        padding:20px;

        .mobile-back{
            float: left;
            padding-bottom: 20px;
        }

        .text{
            width: 50%;
            min-width: 500px;
            float:left;
            margin-bottom:100px
        }
        .subtext{
            width:50%;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            float:left;
            padding-right:20px;
            margin-bottom: 50px;
        }
        .subtextTitle{
            margin-bottom:20px;
        }
    }
}