.browse{
    font-size:20px;
    line-height:30px;
    text-shadow: none;
}


    // background-color:#ccc;
// .interactiveProject .carouselSlide video{
    // box-shadow: 0px 0px 30px rgba(0,0,0,0.3);
// }

.desktopCarouselWrapper{
    position: absolute;
    width: calc(100vw - 400px);
    height: calc(100vh - 200px);
    top: 100px;
    left: 50%;
    transform: translate(-50%);
}

.carouselWrapper{
    position: absolute;
    height: 90vw;
    width: 90vw;
    top: 100px;
    left: 50%;
    transform: translate(-50%);

    .multi-video-wrapper{
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-content: center;
        gap: 100px;
        grid-auto-flow: column;
        align-self: center;

        .single-video-wrapper{

        }
    }
}

.carouselSlider{
    position: absolute;
    width: calc(100vw - 400px);
    height: 100%;
    left: 50%;
    transform: translatex(-50%);
}

.carousel .slide {
    height: 90vw !important;
    width: 90vw !important;
}

.carousel .slide div, .carousel .slide div div, .carousel .slide div div div{
height: 100% !important;
float: left;
position: relative;
width: 100%;
}

.carouselButtonArrow{
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
}

.carouselButtonPrev{
    position: fixed;
    top: 0;
    border: 0;
    height: 100vh;
    left: 0;
    width: 20vw;
    color: black;
    cursor: pointer;

    .carouselButtonArrow{
        left:20px;
        // opacity:0;
        // transition:opacity 0.1s ease-in-out;
    }
}

// .carouselButtonPrev:hover .carouselButtonArrow{
//     opacity:1;
// }

.carouselButtonNext{
    position: fixed;
    top: 0;
    border: 0;
    height: 100vh;
    right: 0;
    width: 20vw;
    color: black;
    cursor: pointer;

    .carouselButtonArrow{
        right:20px;
        // opacity:0;
        // transition:opacity 0.1s ease-in-out;
    }  
}

// .carouselButtonNext:hover .carouselButtonArrow{
//     opacity:1;
// }


.video-player-wrapper{
    position: relative;
}
.video-player-controls{
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(1);
    color: white;
    width:50px;
    height:50px;
    font-size: 50px;
    transition: transform 0.1s ease-in-out;
    cursor:pointer;
}

.video-player-controls:hover{
    transform:translate(-50%, -50%) scale(1.1);
}

.hide-controls{
    opacity: 0;
}

.desktopCarouselSlide{
    position:relative;
    width: calc(100vw - 400px);
    height: calc(100vh - 200px);
}
.display{
    display:block;
}
.hide{
    display:none;
}

.desktopCarouselSlide img{
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.2s ease-in-out;
    cursor:pointer;
}

// .carouselSlide img:hover{
//     transform: translate(-50%, -50%) scale(1.1);
// }

.desktopCarouselSlide video{
    position: relative;
    max-height: calc(100vh - 200px);
    max-width: calc(100vw - 400px);
    left: 50%;
    transform: translatex(-50%) scale(1);
    transition: transform 0.1s ease-in-out;
    // cursor:pointer;
}

mux-player {
    // --controls: none;
    --time-range: none;
    --time-display: none;
    --duration-display: none;
    --airplay-button: none;
    // --volume-range: none;
}


.mux-player-wrapper{
    position: relative;
    // height: 100%;
    // width: 100%;
    height: calc(100vh - 200px);
    // max-width: calc(100vw - 400px);
    left: 50%;
    transform: translatex(-50%) scale(1);
    transition: transform 0.1s ease-in-out;
    cursor:pointer;
}
.interactiveProject .carouselSlide video{
    width: calc(100vw - 400px);
}
// .carouselSlide video:hover{
//     transform: translatex(-50%) scale(1.01);
// }

.loaderWrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    z-index: -1;
}

.project-info{
    opacity: 0;
    z-index: 0;
    padding: 100px 20px 0px 20px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: white;

    .title{
        width:50%;
        min-width:500px;
        font-size: 2vw;
        margin-bottom:50px;
    }

    .text{
        width:50%;
        min-width:500px;
    }
}

.project-info-display{
    opacity: 1;
    z-index: 1;
}

.projectSlideCounter{
    li{
        float:left;
        margin-left:5px;
        padding: 0px 5px;
        border: thin solid white;
        transition: border 0.1s ease-in-out;
    }
    li.selected{
        border: thin solid black;
        // background: rgb(224, 224, 224);
        // padding: 0px 5px;
    }
    li:hover{
        border: thin solid #bfbfbf
        // background: rgb(242, 242, 242);
        
        // padding: 0px 5px;
    }
}

.filterSelector{
    span{
        margin-left:5px;
        cursor:pointer;
        padding: 0px 5px;
        border: thin solid white;
        transition: border 0.1s ease-in-out;

    }
    span:hover{
        border: thin solid #bfbfbf;
        // background: rgb(242, 242, 242);
        // padding: 0px 5px;
    }
    span.selected{
        border: thin solid black;
        // background: rgb(224, 224, 224);
        // padding: 0px 5px;
    }
}

$breakpoint-tablet: 768px;

@media (max-width: $breakpoint-tablet) {

    .browse .bottom{
        height: 54px;
    }
    
    .filterSelector span:first-child{
        margin-left:0;
    }
    .projectSlideCounter li:hover{
        border: none !important;
    }
    
    .projectSlideCounter li:first-child{
        margin-left:0;
    }

    .carouselWrapper{
        width: 90vw;
        height:90vw;
        top:50%;
        transform:translatey(-50%);
        left: 5vw;
        position: fixed;
    }
    .carousel-slider{
        height: auto;
    }
    .carouselSlide{
        width: 90vw;
        height: 90vw;
    }
    // .carouselSlide video{
    //     height: 90vw;
    //     width: 90vw;
    // }
    .slide video{
        display: block;
        max-width: 100%;
        max-height: 100%;
        left: 50%;
        top: 50%;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
    }
    .slide img{
        width: auto !important;
        height: auto !important;
        display: block;
        max-width: 100%;
        max-height: 100%;
        left: 50%;
        position: absolute;
        transform: translatex(-50%);
    }
    .carouselButtonNext{
        // display:none;
    }
    .carouselButtonPrev{
        // display:none;
    }

}