/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* CUSTOM STYLE */

body{
    // font-family: vag-rundschrift-d, sans-serif;
    // font-weight: 400;
    // font-style: normal;
    font-family: "forma-djr-text", sans-serif;
    font-weight: 900;
    font-style: normal;
    color: white;
    font-size: 3vw;
    line-height: 3.3vw;
    background-color:#fff;
    transition:background-color 0.2s ease-in-out;
  }

  em{
    font-style: italic;
    // text-transform: uppercase;
  }

  .underlined{
    text-decoration: underline;
  }
  .no-underline{

  }

    .small-text{
        font-size: 1.5vw;
        line-height: 2vw;
    }

  p:empty{
    margin: 20px 0px;
  }



a{
    // text-decoration: none;
    // border-bottom:thin solid;
    color: inherit;
    // -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: black;
}

a:hover{
    // text-decoration: none;
}

.linkLike{
    cursor:pointer;
}

.button{
    position: fixed;
    top: 10px;
    right: 10px;
    text-align: right;
    z-index: 9;
    // background-color: black;
    line-height: 1.5vw;
    padding:0.1em .5em .3em;
    // padding: 0 0.2em 0.3em 0.2em;
    color:white;
    border-radius: 21px;
    border: 2px solid white;

    a{
        text-decoration: none;
    }
}

.button:hover{
    background-color: white;
    color:black;
}



$breakpoint-tablet: 1000px;

@media (max-width: $breakpoint-tablet) {
  body{
    font-size: 22px;
    line-height: 25px;
  }

  .small-text{
    font-size: 22px;
    line-height: 25px;
}

.button{
    top: auto !important;
    right: auto !important;
    position: relative !important;
    margin: 20px !important;
    /* display: inline-block !important; */
    /* line-height: inherit !important; */
    /* padding: 20px; */
    text-align: center;
    /* border-radius: 50%; */
    /* width: 170px; */
    /* height: 170px; */
    /* vertical-align: middle; */
    display: inline-block;
    

    a{
        text-decoration: none !important;
    }
}
 

    // Photobooth

    .photobooth .pointer {
        margin-left: 0 !important;
    }

    .webcam{
        width:auto !important;
        height:100% !important;
    }
    #visitorSnapPhoto img {
        left: 50% !important;
        transform: translate(-50%, -50%) rotateY(180deg) !important;
    }

}