#home{

    position:relative;
    // background: #000;


    .intro-wrapper{
        padding:10px;
        float:left;
        // text-shadow: 0px 0px 25px black;

        .intro-text{
            margin-bottom: 50px;
            width: 50vw;
        }

        .intro-column{
            
            width:auto;
            float:left;
            margin-right: 40px;
        }
        
    }

    // .submit-button{
    //     position: fixed;
    //     top: 10px;
    //     right: 10px;
    //     text-align: right;
    //     z-index: 9;
    //     background-color: rgb(216, 216, 216);
    //     line-height: 1.5vw;
    //     padding: 0 0.2em 0.3em 0.2em;
    // }

    // .submit-button:hover{
    //     background-color: black;
    //     color:white
    // }

    .btn{
        background-color: white;
        border-radius: 5px;
        padding:5px 10px;
        color:black;
        margin-top: 30px;
        float: left;
        border-bottom:none;
    }
}

.backgroundWrapper{
    position: fixed;
    width:100vw;
    height:100vh;
    z-index: -2;
    top: 0;

    .background-cover{
        position: fixed;
        width:100vw;
        height:100vh;
        z-index: 1;
        top: 0;
        background-color: rgba(0,0,0,0.2);
    }
}

$breakpoint-tablet: 1000px;

@media (max-width: $breakpoint-tablet) {

    .intro-wrapper{
        padding: 20px !important;
        margin-top:50vh;
        // padding-right: 50px !important;
        .intro-text{
            width: 80% !important; 
            margin-bottom: 26px !important;
        }

        .intro-column{
            width: 100% !important;
            margin-bottom:26px;
        }

    }

}